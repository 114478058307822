<template>
  <!-- 融资决议管理列表 -->
  <div class="financingDecisionManageList">
    <!-- 条件查询 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px'
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
      <div slot="btn_after">
      <base-button icon='iconfont iconzhankai' class="foldBtn" :class="{PutawayBtn:fold}" type='default' @click="fold=!fold"></base-button>
    </div>
    </base-form>
    <!-- 列表数据 -->
    <base-table
    class="main-page-table"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        stripe: true,
      }"
      :dataSource.sync="tableData"
      :api="api"
      :getApi="'getPage'"
      :loadCount="loadCount"
      :queryParas='queryParas'
      ref="tableData"
      :webPage="false">
      <template slot="index" slot-scope="scope">{{scope.$index + 1}}</template>
      <template slot="action" slot-scope="scope">
        <IconButton v-if="detailShow(scope.row) " @click="viewInfo(scope.row)" content="查看" icon="iconfont iconchakan"></IconButton>
        <IconButton v-if="editShow(scope.row)" @click="editFinancing(scope.row.keyId,scope.row.instanceId,scope.row.finaaceProductId,scope.row)" content="编辑" icon="iconfont iconbianji3"></IconButton>
        <!-- <IconButton v-if="scope.row.reviewStatus == 10" @click="cancel(scope.row)" content="撤回" icon="iconfont iconchehui"></IconButton> -->
        <!-- <IconButton  @click="back(scope.row)" content="退回" icon="iconfont icona-tuihuipiliangtuihui" v-if='(scope.row.finaaceProductId==="2109041741202530001")&&(   scope.row.fileMakeStatus === 30&&scope.row.reviewStatus === 40)' ></IconButton> -->
      </template>
    </base-table>
     <!-- 列表查看预览文件 -->
    <!-- <preview-dialog  :previsible.sync="previsible" :dialogType="'list'" :fileId="fileId" :fileType="fileType"></preview-dialog> -->
    <pre-view :fileId="fileId" :isOpen='true' :fileType="fileType" :count="count" />
   <back-dialog :visible.sync="visible" :queryTaskResultDTO='queryTaskResultDTO' :taskId='taskId' @handleFilter='handleFilter' />
   <!-- 选择资金方 -->
   <select-fund-side :visible.sync='fundVisible' :fundObj='fundObj' />
  </div>
</template>
<script>
import SelectFundSide from '../components/select-fund-side.vue'
import BackDialog from '@/pages/business/components/back-dialog.vue'
import PreView from '@/components/pre-view/pre-view.vue'
import IconButton from '@/components/common/button/icon-button/icon-button'
import baseForm from '@/components/common/base-form//base-form.vue'
import { dataForm, dataTable } from './utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { progressApi } from '@/api/businessApi'
import { supplierApi } from '@/api/companyApi'
import { getDictLists } from '@/filters/fromDict'
// import PreviewDialog from './components/preview-dialog.vue'
import Storage from '@/utils/storage'
export default {
  name: 'financingDecisionManageList',
  components: { baseForm, BaseTable, BaseButton, IconButton, PreView, BackDialog, SelectFundSide },
  data () {
    return {
      fundObj: {},
      fundVisible: false,
      queryTaskResultDTO: {},
      taskId: '',
      visible: false,
      count: 0,
      fold: false,
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        processStatus: 'FINANCING_RESULT'
      },
      loadCount: 0,
      tableData: [],
      previsible: false,
      gysData: [],
      fileId: '',
      fileType: ''
    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return progressApi
    },
    fileMakeData () {
      return getDictLists('FILE_MAKE_STATUS')
    }
  },
  mounted () {
    this.handleFilter()
    // this.gysList()
  },
  activated () {
    this.handleFilter()
  },
  methods: {
    // 退回
    back (row) {
      if (row.queryTaskResultDTO && row.queryTaskResultDTO.taskId) {
        const params = {
          businessId: row.queryTaskResultDTO.bizId,
          instanceId: row.queryTaskResultDTO.instanceId,
          taskId: row.queryTaskResultDTO.taskId,
          processName: row.processStatusId
        }
        progressApi.updatestatus(params).then(res => {
          if (res.success) {
            this.queryTaskResultDTO = row.queryTaskResultDTO
            this.queryTaskResultDTO.processStatus = this.queryParas.processStatus
            this.taskId = row.queryTaskResultDTO.taskId
            this.visible = true
          }
        })
      } else {
        this.warning('数据错误')
      }
    },
    handleFilter () {
      this.loadCount++
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1,
        processStatus: 'FINANCING_RESULT'
      }
    },
    // 查看按钮显示判断
    detailShow (data) {
      return (data.reviewStatus === 10 || data.reviewStatus === 20 || data.reviewStatus === 30 || data.reviewStatus === 90)
    },
    // 编辑按钮显示判断
    editShow (data) {
      return (((data.reviewStatus === 0 || data.reviewStatus === 10) && data.fileMakeStatus !== 30) || data.reviewStatus === 40 || data.reviewStatus === 50)
    },
    // 融资制作
    editFinancing (keyId, instanceId, finaaceProductId, row) {
      Storage.setLocal('contractInfo', row)
      // 新增需求
      // 产值贷 需先选择资金方
      // 稳享付 无需选择
      if (row.finaaceProductName === '产值贷') {
        this.fundVisible = true
        this.fundObj = {
          keyId: keyId,
          instanceId: instanceId,
          capitalSideId: row.capitalSideId,
          businessNo: row.businessNo,
          gysId: row.gysId
        }
      } else {
        this.api.checkTimeOutOrNot({ businessId: keyId }).then(res => {
          if (res.data.status) {
            this.$router.push({
              path: '/business/decisionMake',
              query: { keyId: keyId, instanceId: instanceId, gysId: row.gysId }
            })
          } else {
            this.warning(res.data.errorMsg)
          }
        })
      }
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    // 融资决议撤回
    cancel (row) {
      this.$confirm('此操作将撤回该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.cancel({ businessId: row.keyId, processStatus: 'FINANCING_RESULT' }).then(res => {
          if (res.success) {
            this.success('撤回成功')
            this.handleFilter()
          }
        })
      }).catch(() => {})
    },
    // 查看
    viewInfo (data) {
      this.fileId = data.fileId
      this.fileType = data.fileName ? data.fileName.split('.').pop() : ''
      // this.previsible = true
      console.log(this.fileType, 'this.fileType')
      this.count++
    }
  }
}
</script>
