<template>
  <div>

    <el-dialog
      title="选择资金方"
      :visible.sync="visibleState"
      width="30%"
      :before-close="handleClose"
      class="backForm"
      :close-on-click-modal="false"
      center
    >
      <base-form
        :componentList="fundForm"
        :showBtns="false"
        :formAttrs="{
          model: addQueryParas,
          labelWidth: '120px'
        }"
        class="formStyle"
        ref="addfromdata"
      />
      <span slot="footer" class="dialog-footer">
        <base-button label="确 认" @click="submit" />
        <base-button
          label="关 闭"
          type="default"
          @click="visibleState = false"
        />
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Storage from '@/utils/storage'
import { progressApi } from '@/api/businessApi'
import { fundForm } from './confing'
import { parameterApi } from '@/api/parameterApi'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: { BaseButton, BaseForm },
  props: {
    taskId: String,
    visible: Boolean,
    fundObj: Object
  },
  data () {
    return {
      providertData: [],
      options: [],
      addQueryParas: {}
    }
  },
  // 计算属性 类似于data概念
  computed: {
    fundForm () {
      return fundForm(this)
    },
    api () {
      return parameterApi
    },
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {
    visible (val) {
      if (val) {
        this.addQueryParas = {
          capitalSideId: this.fundObj.capitalSideId
        }

        this.providertInfo()
        this.$nextTick(() => {
          this.$refs.addfromdata.clearValidate()
        })
      }
    }
  },
  // 方法集合
  methods: {

    handleClose () {
      this.visibleState = false
    },
    // 查询回退节点
    // 获取资金方信息
    providertInfo () {
      const params = {
        replyStatus: 1 // 审核状态
      }
      parameterApi.getFundsprovidertInfo(params)
        .then(res => {
          if (res.data) {
            this.providertData = res.data

            this.providertData.forEach(ele => {
              this.$set(ele, 'capitalAndProductName', ele.capitalSideName + '-' + ele.productName)
            })
          }
        })
    },
    // 提交
    submit () {
      this.$refs.addfromdata.validate(valid => {
        if (valid) {
          progressApi.checkTimeOutOrNot({ businessId: this.fundObj.keyId }).then(res => {
            if (res.data.status) {
              let capitalSideName = ''
              let capitalSideProductId = ''
              let capitalSideProductName = ''
              let capitalSideType = ''
              this.providertData.forEach((item) => {
                if (item.keyId === this.addQueryParas.capitalSideId) {
                  capitalSideName = item.capitalSideName
                  capitalSideProductName = item.productName
                  capitalSideProductId = item.capitalSideProductId
                  capitalSideType = item.capitalSideType
                }
              })
              const data = {
                capitalSideId: this.addQueryParas.capitalSideId,
                capitalSideName: capitalSideName,
                capitalSideProductId: capitalSideProductId,
                capitalSideProductName: capitalSideProductName,
                keyId: this.fundObj.keyId
              }

              progressApi.manageUpdate(data).then(res => {
                if (res.data) {
                  progressApi.getPage({
                    pageIndex: 1,
                    pageSize: 10,
                    processStatus: 'FINANCING_RESULT',
                    businessNo: this.fundObj.businessNo

                  }).then(res => {
                    if (res.data.records.length > 0) {
                      Storage.setLocal('contractInfo', { ...res.data.records[0], capitalSideType })
                      this.$router.push({
                        path: '/business/decisionMake',
                        query: { keyId: this.fundObj.keyId, instanceId: this.fundObj.instanceId, gysId: this.fundObj.gysId }
                      })
                    } else {
                      this.warning('融资信息不存在')
                    }
                  })
                }
              })
            } else {
              this.warning(res.data.errorMsg)
            }
          })
        }
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.backForm {
  height: 100vh;
  overflow: hidden;
  /deep/.el-dialog {
    height: 300px;
    margin-top: 30vh !important ;
  }
  /deep/ .el-dialog__body {
    height: calc(100% - 160px);
  }
  /deep/ .el-dialog__header {
    background: #4A7CF0;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
}
</style>
